import React from "react";
import SubPageTitle from '../components/sub-page-title/subPageTitle';
import Layout from "../components/layout";
import SEO from "../components/seo";
import '../css/polityka-prywatnosci.scss';
export default class PolitykaPrywatnosci extends React.Component {
  
  render(){
    return <Layout>
      <SEO 
        title="Nasza polityka prywatności"
        description="Sprawdź naszą politykę prywatności" 
        url="https://jointoweb.pl/polityka-prywatnosci"
        lang="pl" 
      />
      <div className="section section--brief section--brief--no-bottom">
        <div className="section-brief--title section-brief--title--relative">
          <SubPageTitle text={<span><strong>Polityka</strong> prywatności</span>}/>
        </div>
      </div>
      <div className="section sup-page-content fixed">
        <div className="section--content order-list--wrapper">
          <h3 className="order-list--title">Postanowienia ogólne</h3>
          <ol className="order-list">
            <li className="order-list--item">
              Twoje dane osobowe są przetwarzane przez DOKSYS Sp. z o.o., 
              adres siedziby: 80–126 Gdańsk, ul. Stanisława Lema 4a /1, 
              wpisaną do rejestru przedsiębiorców pod numerem 
              KRS: 0000827103, 
              NIP: 5833388261, 
              REGON: 385492571, posiadającą kapitał zakładowy w kwocie: 5000, 
              adres poczty elektronicznej: <a href="mailto:hello@jointoweb.pl">hello@jointoweb.pl</a> dalej „Administrator” albo „Jointoweb”, będąca jednocześnie Usługodawcą.
            </li>
            <li className="order-list--item">
              Dane osobowe zbierane przez Administratora za pośrednictwem strony internetowej są przetwarzane zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), zwane dalej RODO oraz ustawą o ochronie danych osobowych z dnia 10 maja 2018 r.
            </li>
          </ol>

          <h3 className="order-list--title">Rodzaj przetwarzanych danych osobowych, cel i zakres zbierania danych</h3>
          <ol className="order-list">
            <li className="order-list--item">
              Cel przetwarzania i podstawa prawna. Administrator przetwarza dane osobowe za pośrednictwem strony www.jointoweb.pl w przypadku:
              <ol className="order-list--sub-list">
                <li className="order-list--item order-list--item--sub-list">
                  Skorzystania przez użytkownika z formularza kontaktowego. Dane osobowe są przetwarzane na podstawie art. 6 ust. 1 lit. f) RODO jako prawnie usprawiedliwiony interes Administratora.
                </li>
                <li className="order-list--item order-list--item--sub-list">
                  Zapisania się przez użytkownika do Newslettera w celu przesyłania informacji handlowych drogą elektroniczną. Dane osobowe są przetwarzane po wyrażeniu odrębnej zgody, na podstawie art. 6 ust. 1 lit. a) RODO.
                </li>
              </ol>
            </li>
            <li className="order-list--item">
              Rodzaj przetwarzanych danych osobowych. Administrator przetwarza następujące kategorie danych osobowych użytkownika: imię i nazwisko, data urodzenia, adres (zamieszkania), adres e–mail, numer telefonu, NIP 
            </li>
            <li className="order-list--item">
              Okres archiwizacji danych osobowych. Dane osobowe użytkowników przechowywane są przez Administratora:
              <ol className="order-list--sub-list">
                <li className="order-list--item order-list--item--sub-list">
                  W przypadku, gdy podstawą przetwarzania danych jest wykonanie umowy, tak długo, jak jest to niezbędne do wykonania umowy, a po tym czasie przez okres odpowiadający okresowi przedawnienia roszczeń. Jeżeli przepis szczególny nie stanowi inaczej, termin przedawnienia wynosi lat sześć, a dla roszczeń o świadczenia okresowe oraz roszczeń związanych z prowadzeniem działalności gospodarczej – trzy lata.
                </li>
                <li className="order-list--item order-list--item--sub-list">
                  W przypadku, gdy podstawą przetwarzania danych jest zgoda, tak długo, aż zgoda nie zostanie odwołana, a po odwołaniu zgody przez okres czasu odpowiadający okresowi przedawnienia roszczeń jakie może podnosić Administrator i jakie mogą być podnoszone wobec niego. Jeżeli przepis szczególny nie stanowi inaczej, termin przedawnienia wynosi lat sześć, a dla roszczeń o świadczenia okresowe oraz roszczeń związanych z prowadzeniem działalności gospodarczej – trzy lata.
                </li>
              </ol>
            </li>
            <li className="order-list--item">
              Podczas korzystania ze strony internetowej mogą być pobierane dodatkowe informacje, w szczególności: adres IP przypisany do komputera użytkownika lub zewnętrzny adres IP dostawcy Internetu, nazwa domeny, rodzaj przeglądarki, czas dostępu, typ systemu operacyjnego.
            </li>
            <li className="order-list--item">
              Od użytkowników mogą być także gromadzone dane nawigacyjne, w tym informacje o linkach i odnośnikach, w które zdecydują się kliknąć lub innych czynnościach, podejmowanych na stronie internetowej. Podstawą prawną tego rodzaju czynności jest prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO), polegający na ułatwieniu korzystania z usług świadczonych drogą elektroniczną oraz na poprawie funkcjonalności tych usług.
            </li>
            <li className="order-list--item">
              Podanie danych osobowych przez użytkownika jest dobrowolne.
            </li>
            <li className="order-list--item">
              Dane osobowe będą przetwarzane także w sposób zautomatyzowany w formie profilowania, o ile użytkownik wyrazi na to zgodę na podstawie art. 6 ust. 1 lit. a) RODO. Konsekwencją profilowania będzie przypisanie danej osobie profilu w celu podejmowania dotyczących jej decyzji bądź analizy lub przewidywania jej preferencji, zachowań i postaw.
            </li>
            <li className="order-list--item">
              Administrator dokłada szczególnej staranności w celu ochrony interesów osób, których dane dotyczą, a w szczególności zapewnia, że zbierane przez niego dane są:
              <ol className="order-list--sub-list">
                <li className="order-list--item order-list--item--sub-list">
                  Przetwarzane zgodnie z prawem, 
                </li>
                <li className="order-list--item order-list--item--sub-list">
                  Zbierane dla oznaczonych, zgodnych z prawem celów i niepoddawane dalszemu przetwarzaniu niezgodnemu z tymi celami,
                </li>
                <li className="order-list--item order-list--item--sub-list">
                  Merytorycznie poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane oraz przechowywane w postaci umożliwiającej identyfikację osób, których dotyczą, nie dłużej niż jest to niezbędne do osiągnięcia celu przetwarzania.
                </li>
              </ol>
            </li>
          </ol>

          <h3 className="order-list--title"> Udostępnienie danych osobowych</h3>
          <ol className="order-list">
            <li className="order-list--item">
              Dane osobowe użytkowników przekazywane są dostawcom usług, z których korzysta Administrator przy prowadzeniu strony internetowej. Dostawcy usług, którym przekazywane są dane osobowe, w zależności od uzgodnień umownych i okoliczności, albo podlegają poleceniom Administratora co do celów i sposobów przetwarzania tych danych (podmioty przetwarzające) albo samodzielnie określają cele i sposoby ich przetwarzania (administratorzy).
            </li>
            <li className="order-list--item">
              Dane osobowe użytkowników są przechowywane wyłącznie na terenie Europejskiego Obszaru Gospodarczego (EOG).
            </li>
          </ol>

          <h3 className="order-list--title">Prawo kontroli, dostępu do treści własnych danych oraz ich poprawiania</h3>
          <ol className="order-list">
            <li className="order-list--item">
              Osoba, której dane dotyczą, ma prawo dostępu do treści swoich danych osobowych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo wniesienia sprzeciwu, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.
            </li>
            <li className="order-list--item">
              Podstawy prawne żądania użytkownika: 
              <ol className="order-list--sub-list">
                <li className="order-list--item order-list--item--sub-list">
                  Dostęp do danych – art. 15 RODO
                </li>
                <li className="order-list--item order-list--item--sub-list">
                  Sprostowanie danych – art. 16 RODO.
                </li>
                <li className="order-list--item order-list--item--sub-list">
                  Usunięcie danych (tzw. prawo do bycia zapomnianym) – art. 17 RODO.
                </li>
                <li className="order-list--item order-list--item--sub-list">
                  Ograniczenie przetwarzania – art. 18 RODO.
                </li>
                <li className="order-list--item order-list--item--sub-list">
                  Przeniesienie danych – art. 20 RODO.
                </li>
                <li className="order-list--item order-list--item--sub-list">
                  Sprzeciw – art. 21 RODO
                </li>
                <li className="order-list--item order-list--item--sub-list">
                  Cofnięcie zgody – art. 7 ust. 3 RODO.
                </li>
              </ol>
            </li>
            <li className="order-list--item">
              W celu realizacji uprawnień, o których mowa w pkt 2 można wysłać stosowną wiadomość e–mail na adres: <a href="hello@jointoweb.pl.">hello@jointoweb.pl.</a>
            </li>
            <li className="order-list--item">
              W sytuacji wystąpienia przez użytkownika z uprawnieniem wynikającym z powyższych praw, Administrator spełnia żądanie albo odmawia jego spełnienia niezwłocznie, nie później jednak niż w ciągu miesiąca po jego otrzymaniu. Jeżeli jednak – z uwagi na skomplikowany charakter żądania lub liczbę żądań – Administrator nie będzie mógł spełnić żądania w ciągu miesiąca, spełni je w ciągu kolejnych dwóch miesięcy informując użytkownika uprzednio w terminie miesiąca od otrzymania żądania – o zamierzonym przedłużeniu terminu oraz jego przyczynach.
            </li>
            <li className="order-list--item">
              W przypadku stwierdzenia, że przetwarzanie danych osobowych narusza przepisy RODO, osoba, której dane dotyczą, ma prawo wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych.
            </li>
          </ol>

          <h3 className="order-list--title">Pliki "cookies"</h3>
          <ol className="order-list">
            <li className="order-list--item">
              Strona Administratora używa plików „cookies”.</li>
            <li className="order-list--item">
              Instalacja plików „cookies” jest konieczna do prawidłowego świadczenia usług na stronie internetowej. W plikach „cookies" znajdują się informacje niezbędne do prawidłowego funkcjonowania strony, a także dają one także możliwość opracowywania ogólnych statystyk odwiedzin strony internetowej.</li>
            <li className="order-list--item">
              W ramach strony stosowane są rodzaje plików "cookies": sesyjne i stałe
              <ol className="order-list--sub-list">
                <li className="order-list--item order-list--item--sub-list">
                  „Cookies” „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym użytkownika do czasu wylogowania (opuszczenia strony). 
                </li>
                <li className="order-list--item order-list--item--sub-list">
                  „Stałe” pliki „cookies” przechowywane są w urządzeniu końcowym użytkownika przez czas określony w parametrach plików „cookies” lub do czasu ich usunięcia przez użytkownika.
                </li>
              </ol>
            </li>
            <li className="order-list--item">
              Administrator wykorzystuje własne pliki cookies w celu lepszego poznania sposobu interakcji użytkownika w zakresie zawartości strony. Pliki gromadzą informacje o sposobie korzystania ze strony internetowej przez użytkownika, typie strony, z jakiej użytkownik został przekierowany oraz liczbie odwiedzin i czasie wizyty użytkownika na stronie internetowej. Informacje te nie rejestrują konkretnych danych osobowych użytkownika, lecz służą do opracowania statystyk korzystania ze strony.
            </li>
            <li className="order-list--item">
              Użytkownik ma prawo zadecydowania w zakresie dostępu plików „cookies” do swojego komputera poprzez ich uprzedni wybór w oknie swojej przeglądarki.  Szczegółowe informacje o możliwości i sposobach obsługi plików „cookies” dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).
            </li>
          </ol>

          <h3 className="order-list--title">Postanowienia końcowe</h3>
          <ol className="order-list">
            <li className="order-list--item">
              Administrator stosuje środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych osobowych odpowiednią do zagrożeń oraz kategorii danych objętych ochroną, a w szczególności zabezpiecza dane przed ich udostępnieniem osobom nieupoważnionym, zabraniem przez osobę nieuprawnioną, przetwarzaniem z naruszeniem obowiązujących przepisów oraz zmianą, utratą, uszkodzeniem lub zniszczeniem.
            </li>
            <li className="order-list--item">
              Administrator udostępnia odpowiednie środki techniczne zapobiegające pozyskiwaniu i modyfikowaniu przez osoby nieuprawnione, danych osobowych przesyłanych drogą elektroniczną.
            </li>
            <li className="order-list--item">
              W sprawach nieuregulowanych niniejszą Polityką prywatności stosuje się odpowiednio przepisy RODO oraz inne właściwe przepisy prawa polskiego.
            </li>
          </ol>
        </div>
      </div>
    </Layout>
  }
}